import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const PtPage = () => (
    <Layout>
        <Seo 
            title="Web development case study" 
            description="Case study of the Gatsby and Contentful web development headless CMS project completed by Launch Lab for construction tech startup PT Blink"
        />

        <Hero
            h1="Construction tech startup web design &amp; development case study"  
            h2="PT Blink"
        />

        <section className="bg-noise pt section-pad-sm">
            <div className="container-lg">
                <div className="row">
                    <StaticImage 
                        src="../images/pt-blink-main.png"
                        alt="Screens of the PT Blink website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>

        <section className="section-pad-sm" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#testimonial')} className="btn">
                                        Testimonials
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>PT Blink is an Australian construction technology startup. To fuel their growth PT Blink undertook a brand re-design which also required a new marketing website and content management system.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/startup-web-designers/" className="btn btn-lg btn-line">Web design</Link>
                                    <Link to="/gatsby-developer/" className="btn btn-lg btn-line">Gatsby.js</Link>
                                    <Link to="/web-development/" className="btn btn-lg btn-line">Web development</Link>
                                    <Link to="/headless-cms/" className="btn btn-lg btn-line">Contentful CMS integration</Link>
                                    <Link to="/aws-infrastructure/" className="btn btn-lg btn-line">AWS</Link>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>Launch Lab designed and developed the new PT Blink website using the popular Gatsby static site generator library.</p>
                                    <p>Contentful was used as the CMS ensuring an easy-to-use publishing platform.</p>
                                </div>
                                <div id="testimonial" className="porti-text-section">
                                    <h4>Testimonials</h4>
                                    <Testimonial
                                        excerpt="Great job"
                                        quote="“Many thanks to you and the team. You did a great job and it was a pleasure working with you.”"
                                        name="Andrew Giles"
                                        title="Marketing Director, PT Blink"
                                    />
                                    <Testimonial
                                        excerpt="Exceptional"
                                        quote="“It was great working with you and the team. The website is exceptional and a major, major improvement.”"
                                        name="Grant Ure"
                                        title="CTO, PT Blink"
                                    />
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the new PT Blink website.</p>
                                    <a href="https://ptblink.com/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>

        <Cta 
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default PtPage